.label {
  .mat-datepicker-toggle .mat-mdc-button-base {
    width: 36px;
    height: 34px;
    line-height: 36px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

    &:disabled {
      color: #00000042;
    }
  }

  .mat-datepicker-toggle {
    .mdc-icon-button svg, .mdc-icon-button img {
      width: 20px;
      height: 20px;
      line-height: 20px;
    }
  }
}

.label._date .mat-datepicker-toggle, .label._date mat-datepicker {
  height: 100%;
}
