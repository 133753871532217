@import "mixins";
@import "variables";
@import "colors";
@import "select";
.introjs-tooltipReferenceLayer {
  @include font-stack-main();


  .introjs-tooltip {
    display: flex;
    width: 320px;
    max-width: 320px;
    min-height: 150px;
    padding: 0;
    border-radius: 8px;
    flex-direction: column;
    align-items: flex-start;

    .introjs-left {
      top: 45px !important;
    }
  }

  .introjs-tooltip-header {
    display: flex;
    padding: 8px 16px;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    border-radius: 8px 8px 0 0;
    background: $color-blue;
  }

  .introjs-helperNumberLayer {
    position: absolute;
    top: 0;
    left: 8px;
    padding: 8px;

    color: $color-white;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.15px;

    &::before {
      content: 'Шаг';
      margin-right: 4px;

    }
  }

  .introjs-skipbutton {
    position: relative;
    display: flex;
    align-items: right;
    color: $color-white;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    width: 147px;
    height: 20px;
  }

  .introjs-tooltip-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.15px;
    margin: 0;
    padding: 0 0 0 16px;
    width: 320px;
    text-align: left;
    position: absolute;
    top: 54px;
    left: 0;
  }

  .introjs-tooltiptext {
    margin-top: 46px;
    color: $color-dark-grey-text;
    font-size: 14px;
    padding: 0 16px 0;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    letter-spacing: 0.15px;
  }

  .introjs-tooltipbuttons {
    display: flex;
    justify-content: space-between;
    border: none;
    width: 288px;
    padding: 16px;

    &::after {
      display: none;
    }

    .introjs-button {
      display: flex;
      padding: 8px 12px 8px 8px;
      justify-content: center;
      border: none;
      align-items: center;
      color: $color-blue;
      border-radius: 4px;
      background: rgba(25, 133, 255, 0.05);


      &:focus {
        outline: 0;
        text-decoration: none;
        border-radius: 4px;
        border: 1px solid $color-light-grey;
        box-shadow: none;
        color: $color-blue;
      }

      &.introjs-donebutton {
        padding: 8px 8px 8px 12px;
        color: $color-white;
        background: $color-blue;
        text-shadow: none;
        width: calc(100% - 8px);
      }
    }

    .introjs-nextbutton::after {
      content: '';
      background: center no-repeat url("../icons/arrow.svg"), $color-blue;
      flex-shrink: 0;
      margin-left: 8px;
      border-radius: 24px;
      width: 20px;
      height: 20px;
      padding: 2px;
      justify-content: center;
      align-items: center;
    }

    .introjs-donebutton::after {
      display: none;
    }

    .introjs-prevbutton {
      margin-right: 8px;

      &::before {
      content: '';
      background: center no-repeat url("../icons/arrow_blue.svg"), $color-blue-hover-light;
      transform: rotate(180deg);
      flex-shrink: 0;
      margin-right: 8px;
      border-radius: 24px;
      width: 20px;
      height: 20px;
      padding: 2px;
      justify-content: center;
      align-items: center;
    }
  }
    .introjs-disabled {
      opacity: 0;
    }

    .introjs-nextbutton.skip {
      @include font-stack-main();
      color: $color-white;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.1px;
      background-color: $color-blue-default;
      padding: 8px 16px;
      border-radius: 4px;
      display: flex;
      cursor: pointer;
      justify-content: center;
      align-items: center;
    }
  }

  .introjs-arrow {
    display: none !important;

  }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
  max-width: 320px;
  margin: 0px;
}

.my-anchor-el.touranchor--is-active {
  padding: 10px;
  margin: -10px;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 10000;
}
