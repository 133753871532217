@import "variables";

.progress-bar {
  height: 8px !important;

  .mdc-linear-progress__bar-inner {
    display: none;
  }

  .mdc-linear-progress__bar {
    height: 100%;
    margin: 0;
  }

  & .mdc-linear-progress__buffer-bar {
    background-color: $color-light-grey-hover;
    border-radius: 8px;
  }

  & .mdc-linear-progress__bar-inner {
    border-top-width: 8px;
  }

  &._grey {
    .mdc-linear-progress__bar {
      background: linear-gradient(90deg, $color-grey 0%, #C0C9D5 80.3%);
    }
  }

  &._orange {
    .mdc-linear-progress__bar {
      background: linear-gradient(90deg, #FFB800 0%, #FFDB7E 100%);
    }
  }

  &._blue {
    .mdc-linear-progress__bar {
      background: linear-gradient(90deg, #1985FF 0%, #68AFFF 100%);
    }
  }

  &._green {
    .mdc-linear-progress__bar {
      background: linear-gradient(90deg, #2FBF6F 0%, #37D77E 100%);
    }
  }

  &._dark-green {
    & .mdc-linear-progress__bar {
      background-color: $color-green-hover;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 8px;
  }
}
