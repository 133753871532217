@import 'variables';

$border-on: 1px solid $color-light-grey-68;
$border-off: 1px solid transparent;

.mat-mdc-radio-button .mdc-radio {
    padding: 0 !important;
}

.mat-internal-form-field>label {
    padding-left: 8px !important;
}

.mat-mdc-radio-button {
    .mat-internal-form-field>label {
        padding-left: 8px !important;
    }
}

.radio-group._blue {
    display: flex;
    gap: 5px;

    .radio-btn {
        --mdc-radio-unselected-icon-color: #{$color-grey};
        --mat-radio-label-text-weight: 500;
        --mat-radio-label-text-tracking: 0.1px;
        --mat-radio-label-text-color: #{$color-dark-grey-text};
        border: $border-on;
        border-radius: 24px;
        box-sizing: border-box;
        min-width: 64px;
        padding: 6px 8px;
        cursor: pointer;
    }

    & .border {
        border-right: $border-on;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .radio-btn.mat-mdc-radio-checked {
        --mat-radio-label-text-color: #{$color-blue};
        border: $border-off;
        background: $color-table-h-2;
    }

    .mat-mdc-radio-button {
        & mat-icon {
            position: relative;
            top: 3px;
        }
    }
}

.mat-mdc-radio-group {
    .mat-mdc-radio-button {
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
        .mat-radio-label-content {
            text-wrap: wrap;
        }
    }
}

.mat-mdc-radio-button .mdc-radio__background {
    &:before {
        display: none;
    }
}
