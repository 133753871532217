@import "mixins";
@import "variables";
@import "colors";
@import "select";

.close-btn-icon {
  width: 17px;
  height: 17px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 50%;
  background-color: $color-grey-68;
  color: $color-white;
  font-size: 15px;
  line-height: 17px;
  outline: none;

  &:hover {
    background-color: $color-grey;
  }
}

.datepicker-toggler {
  color: $color-grey-68;

  &:hover {
    color: $color-grey;
  }
}

.mat-mdc-form-field-error {
  display: block;
  @include font-stack-main;
  padding-left: 0;
  margin-top: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $color-error;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-flex {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-suffix {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}

.mat-mdc-form-field._table {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-suffix {
    position: relative;
    top: 0px;
    right: 0;

    .mat-datepicker-toggle-default-icon {
      width: 25px;
      color: $color-blue;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  width: auto !important;
}

.mat-datepicker-toggle {
  &:active,
  &:focus {
    outline: none;
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 24px;
  left: 10px;
  font-size: 14px;
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  display: flex;
  width: auto;
  top: 24px;
  left: 5px;
  padding: 0 8px;
  background: linear-gradient(to bottom, transparent 50%, $color-white 50%);
}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 2px;
}

.kit {
  @include font-stack-main;
  display: block;
  position: relative;

  &._company {
    .textarea, .textarea:disabled {
      color: $color-main-text;
    }
  }

  .textarea {
    &._task-title, &._position-title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      color: $color-main-text;

      &::placeholder {
        font-weight: 400;
      }
    }

    &._grey {
      height: 90px;
      padding: 8px 12px;
      border-radius: 4px;
      background-color: $color-light-grey-40;
      //border: 2px solid $color-light-grey-40;

      &:focus,
      &:active {
        background-color: $color-white;
        border-color: transparent transparent $color-blue-active transparent;
        border: 2px solid $color-blue-active;
        outline: none;
        cursor: text;
      }
    }

    &:disabled {
      color: $color-grey-68
    }
  }

  .input {
    width: 100%;
    min-height: 36px;;
    padding: 8px 8px 6px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 2px solid transparent;
    cursor: text;
    font-size: 14px;
    line-height: 16px;
    background-color: $color-light-grey-40;
    color: $color-main-text;
    vertical-align: middle;
    @include font-stack-main;

    &._thin {
      min-height: 32px;
      height: 32px;
    }

    &::placeholder {
      color: $color-grey-68;
    }

    &._error {
      &,
      &:focus,
      &:active {
        border: 2px solid $color-error;
      }
    }

    &:hover {
      background-color: $color-light-grey-40;
    }

    &:focus,
    &:active {
      background-color: $color-white;
      border-color: transparent transparent $color-blue-active transparent;
      border: 2px solid $color-blue-active;
      outline: none;
      cursor: text;
    }

    &:disabled, &._disabled {
      color: $color-grey-60;
      cursor: auto;
      &:focus,
      &:active {
        border: 2px solid transparent;
      }
    }

    &._read-only {
      cursor: text;
      background-color: $color-light-grey-40;
      color: $color-main-text;
      &:focus,
      &:active {
        border: 2px solid transparent;
      }
    }
  }

  .select {
    cursor: pointer;
  }
  .textarea {
    z-index: 1;
    width: 100%;
    min-height: 20px;
    padding: 0;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 20px;
    color: $color-main-text;
    vertical-align: middle;
    background-color: transparent;
    @include font-stack-main;

    &._grey {
      background-color: $color-light-grey-40;
    }

    &:not([disabled]) {
      cursor: text;

      &._error {
        &:focus + .textarea-wrap__emul,
        &:active + .textarea-wrap__emul,
        & + .textarea-wrap__emul {
          background-color: transparent;
          border: 2px solid $color-error;
        }
      }

      &:hover {
        & + .textarea-wrap__emul {
          background-color: $color-light-grey-24;
        }
      }

      &:focus,
      &:active {
        & + .textarea-wrap__emul {
          background-color: transparent;
          border: 2px solid $color-blue;
          outline: none;
          cursor: text;
        }
      }
    }

    &::placeholder {
      color: $color-grey-68;
    }
  }

  .textarea-wrap {
    position: relative;
    z-index: 1;
    display: block;
    box-sizing: border-box;

    & + .mat-mdc-form-field-error {
      margin-top: 9px;
    }

    &._task-name {
      & + .mat-mdc-form-field-error {
        margin-top: 6px;
      }
    }
  }

  .textarea-wrap__emul {
    position: absolute;
    z-index: -1;
    top: -8px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 16px);
    box-sizing: border-box;
    border-radius: 4px;
  }
}

.label__txt {
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: $color-dark-grey-text;
  @include font-stack-main;
  margin-bottom: 8px;

  .mat-icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-right: 6px;
    @include icon-color($color-dark-grey-text);
  }
}

.txt.error {
  padding-left: $grid * 2;
  color: $color-error;
  font-size: 14px;
  font-weight: 500;
}

// Позиционирование для всплывания календаря где надо
.range-picker {
  position: absolute;
  right: 0;
  top: 28px;
  width: 1px; // если 0, всплывет где попало
  height: 1px;
  z-index: -1;
  opacity: 0;

  &._editable {
    opacity: 1;
    height: auto;
    width: auto;
    top: 10px;
    left: 8px;
    right: 64px;
    z-index: 1;
    outline: none;
    font-size: 14px;

    input._hidden {
      visibility: hidden;
    }
  }
}

.input._range {
  display: flex;
  height: 36px;
  padding: 8px 12px !important;
  cursor: pointer;
  color: $color-grey-68;
  outline: none;
}

.close-button {
  position: absolute;
  right: 9px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 20px;
  outline: none;
}

.datepicker-toggler {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
  outline: none;
}

/*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.mat-select-disabled {
  border: none;
}

.label._date {
  .mat-datepicker-toggle, mat-datepicker {
      .mat-mdc-icon-button {
        width: 36px;
        height: 36px;
        line-height: 36px;

        svg {
          width: 20px;
        }
      }
    position: absolute;
    right: 0;
  }
}

.postfix {
  input.mr-24 {
    padding-right: 24px;
  }

  input.mr-18 {
    padding-right: 18px;
  }

  input.mr-16 {
    padding-right: 16px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.2px;

    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-grey;
  }
}

.textarea-label {
  padding: 0px 0px 0 10px !important;
}

.textarea {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
