@import "mixins";
@import 'fonts';
@import "variables";
//@import "buttons";

.cal-month-view .cal-header {
  text-align: center;
  font-weight: bolder;
}

.cal-month-view .cal-header .cal-cell {
  padding: 5px 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap; }

.cal-month-view .cal-cell-top {
  min-height: 78px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.cal-month-view .cal-cell-row {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex; }

.cal-month-view .cal-cell {
  float: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
.cal-month-view .cal-cell .cal-event {
  pointer-events: all !important; }

.cal-month-view .cal-day-cell {
  background: $color-white;
}
@media all and (-ms-high-contrast: none) {
  .cal-month-view .cal-day-cell {
    display: block; } }

.cal-month-view .cal-day-badge {
  margin-top: 18px;
  margin-left: 10px;
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px; }

.cal-month-view .cal-day-number {
  font-size: 1.2em;
  font-weight: 400;
  opacity: 0.5;
  margin-top: 15px;
  margin-right: 15px;
  float: right;
  margin-bottom: 10px; }

.cal-month-view .cal-events {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin: 3px;
  line-height: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.cal-month-view .cal-event {
  width: 100%;
  border-radius: 50%;
  display: inline-block;
  height: 16px;
  margin: 0;
  box-sizing: border-box;
}

.cal-month-view .cal-day-cell.cal-in-month.cal-has-events {
  cursor: pointer; }

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  cursor: default; }

.cal-month-view .cal-day-cell.cal-today .cal-day-number {
  font-size: 1.9em; }

.cal-month-view .cal-open-day-events {
  padding: 15px; }

.cal-month-view .cal-open-day-events .cal-event {
  position: relative;
  top: 2px; }


.cal-month-view .cal-draggable {
  cursor: pointer; }

.cal-month-view .cal-drag-active * {
  pointer-events: none; }

.cal-month-view .cal-event-title {
  cursor: pointer; }
.cal-month-view .cal-event-title:hover {
  text-decoration: underline; }



.cal-week-view {
  /* stylelint-disable-next-line selector-type-no-unknown */ }
.cal-week-view * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
.cal-day-headers {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  position: sticky;
  box-shadow: $box-shadow1;
  top: 0;
  background: #fff;
  z-index: 100;
  padding-left: 53px;
  border: 1px solid;
}
.cal-day-headers .cal-header {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}
.cal-day-headers .cal-header:not(:last-child) {
  border-right: 1px solid; }
.cal-day-headers .cal-header:first-child {
  border-left: 1px solid; }
.cal-week-view .cal-day-column {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  border-left: solid 1px; }
.cal-week-view .cal-event {
  font-size: 12px;
  border: 1px solid; }
.cal-week-view .cal-time-label-column {
  width: 52px;
  height: 100%; }
.cal-week-view .cal-current-time-marker {
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 2; }
.cal-week-view .cal-all-day-events {
  border: solid 1px;
  border-top: 0;
  border-bottom: 1px solid;
  box-shadow:  $box-shadow2;
  position: relative; }
.cal-week-view .cal-all-day-events .cal-day-columns {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  position: absolute;
  top: 0;
  min-height: 32px;

  padding-left: 53px;
  z-index: 0;
}

.collapsed-calendar {
  .cal-week-view .cal-all-day-events .cal-day-columns {
    min-height: initial;
  }
}

.cal-week-view .cal-all-day-events .cal-events-row {
  position: relative;
  height: 23px;
  margin-left: 53px;
  top: 2px}
.cal-week-view .cal-all-day-events .cal-event-container {
  display: inline-block;
  position: absolute; }
.cal-week-view .cal-all-day-events .cal-event-container.resize-active {
  z-index: 1;
  pointer-events: none; }
.cal-week-view .cal-all-day-events .cal-event {
  padding: 0 5px;
  margin-left: 2px;
  margin-right: 2px;
  height: 20px;
  line-height: 20px; }
.cal-week-view .cal-all-day-events .cal-starts-within-week .cal-event {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px; }
.cal-week-view .cal-all-day-events .cal-ends-within-week .cal-event {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px; }
.cal-week-view .cal-all-day-events .cal-time-label-column {
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 14px; }
.cal-week-view .cal-all-day-events .cal-resize-handle {
  width: 6px;
  height: 100%;
  cursor: col-resize;
  position: absolute;
  top: 0; }
.cal-week-view .cal-all-day-events .cal-resize-handle.cal-resize-handle-after-end {
  right: 0; }
.cal-week-view .cal-event,
.cal-week-view .cal-header {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap; }
.cal-week-view .cal-drag-active {
  pointer-events: none;
  z-index: 1; }
.cal-week-view .cal-drag-active * {
  pointer-events: none; }
.cal-week-view .cal-time-events {
  position: relative;
  border: solid 1px;
  border-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex; }
.cal-week-view .cal-time-events .cal-day-columns {
  //height: 1440px;
  display: -webkit-box;
  display: -ms-flexbox;
  -js-display: flex;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1; }
.cal-week-view .cal-time-events .cal-day-column {
  position: relative; }
.cal-week-view .cal-time-events .cal-events-container {
  position: relative; }
.cal-week-view .cal-time-events .cal-event-container {
  position: absolute;
  z-index: 1; }
.cal-week-view .cal-time-events .cal-event {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  margin: 1px;
  padding: 0 5px;
  line-height: 25px; }
.cal-week-view .cal-time-events .cal-resize-handle {
  width: 100%;
  height: 4px;
  cursor: row-resize;
  position: absolute; }
.cal-week-view .cal-time-events .cal-resize-handle.cal-resize-handle-after-end {
  bottom: 0; }
.cal-week-view .cal-hour-segment {
  position: relative; }
//.cal-week-view .cal-hour-segment::after {
//  content: '\00a0'; }
.cal-week-view .cal-event-container:not(.cal-draggable) {
  cursor: pointer; }
.cal-week-view .cal-draggable {
  cursor: pointer; }
.cal-week-view mwl-calendar-week-view-hour-segment,
.cal-week-view .cal-hour-segment {
  display: block; }
// Разделение ячеек по полчаса
//.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
//.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
//  border-bottom: thin dashed; }
.cal-after-hour-start {
  border-bottom: 1px solid $color-light-grey-68;
}
.cal-week-view .cal-time {
  width: 52px;
  text-align: center; }
.cal-week-view .cal-hour-segment.cal-after-hour-start .cal-time {
  display: none; }
.cal-week-view .cal-starts-within-day .cal-event {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }
.cal-week-view .cal-ends-within-day .cal-event {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.cal-week-view {
  background-color: #fff; }
.cal-day-headers {
  border-color: $color-light-grey-68;
  border-top: 0; }
.cal-day-headers .cal-header:not(:last-child) {
  border-right-color: $color-light-grey-68; }
.cal-day-headers .cal-header:first-child {
  border-left-color: $color-light-grey-68; }

.cal-week-view .cal-day-column {
  border-left-color: $color-light-grey-68; }
.cal-week-view .cal-event {
  background-color: #d1e8ff;
  border-color: $color-blue-hover-light;
  color: $color-blue-hover-light }
.cal-week-view .cal-all-day-events {
  border-color: #e1e1e1; }
.cal-week-view .cal-header.cal-today {
  color: $color-blue; }
//.cal-week-view .cal-header.cal-weekend span {
//  color: #8b0000; }
.cal-week-view .cal-time-events {
  border-color: #e1e1e1; }
//.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
//  background-color: #ededed; }
//.cal-week-view .cal-hour-odd {
//  background-color: #fafafa; }
.cal-week-view .cal-drag-over .cal-hour-segment {
  background-color: #ededed; }
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: $color-light-grey-68;; }
.cal-week-view .cal-current-time-marker {
  background-color: #ea4334;
}
.cal-week-view .cal-current-time-marker:before {
  background: #ea4335;
  border-radius: 50%;
  content: "";
  position: absolute;
  height: 6px;
  margin-left: -4px;
  margin-top: -2px;
  width: 6px;
}
.cal-week-view .cal-current-time-marker:after {
  background: #ff0000;
  content: "";
  position: absolute;
  height: 2px;
  width: 200vw;
  left: -100vw;
  opacity: 0.25;
}
.cal-day-columns {
  /* hide current time marker overflow */
  overflow: hidden;
}

.cal-day-view {
  /* stylelint-disable-next-line selector-type-no-unknown */ }
.cal-day-view mwl-calendar-week-view-header {
  display: none; }
.cal-day-view .cal-events-container {
  margin-left: 70px; }
.cal-day-view .cal-day-column {
  border-left: 0; }
.cal-day-view .cal-current-time-marker {
  margin-left: 70px;
  width: calc(100% - 70px); }

.cal-tooltip {
  @include font-stack-main;
  position: absolute;
  z-index: 1070;
  display: block;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  font-size: 10px;
  word-wrap: break-word;
  opacity: 0.9; }

.cal-tooltip.cal-tooltip-top {
  padding: 5px 0;
  margin-top: -3px; }

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0; }

.cal-tooltip.cal-tooltip-right {
  padding: 0 5px;
  margin-left: 3px; }

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0; }

.cal-tooltip.cal-tooltip-bottom {
  padding: 5px 0;
  margin-top: 3px; }

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px; }

.cal-tooltip.cal-tooltip-left {
  padding: 0 5px;
  margin-left: -3px; }

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px; }

.cal-tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  text-align: center;
  border-radius: 0.25rem; }

.cal-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid; }

.cal-tooltip.cal-tooltip-top .cal-tooltip-arrow {
  border-top-color: #000; }

.cal-tooltip.cal-tooltip-right .cal-tooltip-arrow {
  border-right-color: #000; }

.cal-tooltip.cal-tooltip-bottom .cal-tooltip-arrow {
  border-bottom-color: #000; }

.cal-tooltip.cal-tooltip-left .cal-tooltip-arrow {
  border-left-color: #000; }

.cal-tooltip-inner {
  color: #fff;
  background-color: #000; }
//.cal-hour {
//  border-bottom: 1px solid $color-light-grey-68;
//}
.cal-event {
  border-left: 4px solid!important;
  border-radius: 2px!important;
}

.custom-event span {
  color: #4A586D;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.decline-event span {
  color: #4A586D;
  text-decoration: line-through;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.cal-hour > *:first-child {
  border-bottom: 1px dashed $color-light-grey-68;
}
.cal-time-label-column .cal-hour-segment, .cal-time-label-column .cal-hour > *  {
  border: none;
}
.cal-time-label-column .cal-hour:first-child .cal-time {
  display: none;
}
.cal-time-label-column .cal-time {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: $color-dark-grey-text;
  position: relative;
  top: -50%;
  text-align: right;
  left: -12px;
}

.cal-hour-segment {
  padding: 2px;
}

.weekday-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: $color-grey-100;
}
.weekday-number {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: $color-dark-grey-text;
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px 6px 12px;
}

.cal-today.cal-header {
  .weekday-title {
    color: $color-blue-default;
  }
  .weekday-number {
    background: $color-blue-default;
    color: $color-white;
  }
}

.cal-time-events {
  .cal-day-column:nth-child(6), .cal-day-column:nth-child(7) {
    background: $color-light-grey-24;
  }
}

.cal-all-day-events .cal-day-columns {
  .cal-day-column:nth-child(6), .cal-day-column:nth-child(7) {
    background: $color-light-grey-24;
  }
}

.cal-header:nth-child(6), .cal-header:nth-child(7) {
  background: $color-light-grey-24;
}

.cal-event._task {
  background: $color-white !important;
  border: 1px solid $color-light-grey-68 !important;
  opacity: 1;
  padding: 0px !important;
  outline-color: $color-blue-default !important;

  &._done, &._canceled {
    background-color: $color-light-grey-40 !important;
    border-color: $color-light-grey-68 !important;

    .cal-event-title {
      text-decoration: none !important;
    }
  }

  .cal-event-title {
    display: flex;
    align-items: center;
    gap: 2px;
    height: 100%;

    .txt {
      width: calc(100% - 16px);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color-dark-grey-text;
      letter-spacing: 0.2px;

      padding: 2px 6px;
      height: 100%;
      display: flex;
      align-items: center;
    }

    .icon {
      width: 16px;
      height: 16px;
      font-size: 16px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.cal-event {
  padding: 5px;
  white-space: normal;
  line-height: 18px;
  min-height: 16px;

  &._wanted:not(._canceled) {
    background: repeating-linear-gradient(-45deg,$color-light-grey-40,$color-light-grey-40 5px,$color-white 5px,$color-white 10px);
  }
}

.task-left-icon {
  background: rgba(25, 133, 255, 0.1);
  width: 24px;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 20px;
}

.task-left-icon__bar {
  height: 100%;
  width: 4px;
  background: rgba(25, 133, 255, 0.1);
  flex-grow: 0;
  flex-shrink: 0;

  &.is-new {
    background: $color-blue-default;
  }
}

.task-left-icon__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  .icon {
    @include statusColors;
  }
}

.cal-event._task {
  &._overdue {
    .task-left-icon {
      background: $color-error-bg !important;
    }

    .task-left-icon__bar {
      background: $color-error-bg3 !important;
    }
  }

  &._done, &._canceled {

    background: $color-light-grey-24-no-alpha !important;

    cal-event-title .txt {
      color: $color-grey-100;
    }

    .task-left-icon {
      background: $color-light-grey-40 !important;
    }

    .task-left-icon__bar {
      background: $color-light-grey !important;
    }
  }
}

.cal-week-view .cal-event:not(._task) {
  padding: 2px 4px 4px !important;
}

.cal-month-view .custom-event {
  display: flex;
  margin-bottom: 1px;

  .cal-event {
    border: none !important;
    padding: 0 4px;
  }
}

.sticky-head {
  position: sticky !important;
  top: 1px;
  z-index: 100;
  background: white;
  max-height: 50vh;
  box-shadow: 0px 8px 16px -4px rgba(74, 88, 109, 0.16);
  transform: translateY(-1px);
}

.scrollable-cal {
  //overflow: auto;
  max-height: 50vh;

  //&::-webkit-scrollbar {
  //  display: none;
  //}

  .cal-time-label-column {
    display: none;
  }
}

.cal-week-view .cal-all-day-events {
  min-height: 37px;
}

.collapsed-calendar .cal-week-view .cal-all-day-events {
  min-height: 0;
  display: none;
}

.cal-day-columns {
  padding-left: 1px;
}


.cal-month-view{
  height: 100%;
  padding-left: 52px;

  .cal-days {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid $color-light-grey-68;
    overflow-y: auto;
    @include scroll;
  }
}

.cal-month-view .cal-day-cell {
  padding: 2px;
  cursor: pointer;
}

.cal-month-view .cal-days .cal-cell-row {
  flex: 1;
}

.cal-month-view .cal-days .cal-cell-row {
  border-bottom: 1px solid $color-light-grey-68; }

.month-cell__day {
  color: $color-dark-grey-text;
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.15px;
}

.month-cell__day._float {
  float: right;
  transform: translate(-8px, 5px);
  text-align: right;
  width: 24px;
  height: 24px;
  position: sticky;
  z-index: 100;
  top: 3px;
}

.cal-out-month .month-cell__day {
  color: $color-grey-40;
}

.cal-days .cal-cell-row .month-cell__weekday .txt {
  display: none;
}

.cal-days .cal-cell-row:first-child .month-cell__weekday .txt {
  display: inline-block;
}

.cal-today .month-cell__day {
  background: $color-blue-default;
  color: $color-white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -2px;
}

.cal-week-view .event-time {
  display: none !important;
}

.cal-month-view .cal-event-title .title {
  -webkit-line-clamp: 1 !important;
  width: 98%;
  word-break: break-all;
}

.cal-event-title {
  width: 100%;
}

.cal-month-view .cal-weekend {
  background: rgb(245 247 250) !important;
}

.cal-hour-segment {
  cursor: pointer;
}

.cal-task-menu  {
  .cal-event._task .cal-event-title {
    .task-left-icon {
      align-self: stretch;
      height: initial;
    }
    .txt {
      white-space: break-spaces;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      word-wrap: break-word;
      /* max-height: 32px; */
      /* visibility: visible; */
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}

.month-cell {
  flex: 1;
}


.month-cell__header {
  display: flex;
  margin: 0 2px;
  padding: 7px 8px 4px;
  border-bottom: 1px solid $color-light-grey-40;
}

.month-cell__weekday {
  flex: 1;

  color: $color-grey-100;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
}

.sticky-header-month {
  display: flex;
  flex-direction: row;
  box-shadow: $box-shadow2;
  padding-left: 51px;

  height: 36px;
  margin-bottom: -36px;
  box-sizing: border-box;
  position: sticky;
  top: 0;

  .month-cell__header {
    border-bottom: none;
    flex: 1;
    padding: 0;
  }
}

.cal-days {
  @include scroll;
}

.scroll-compensation {
  background: rgb(245, 247, 250) !important;
  display: inline-block;
  height: 100%;
}

.cal-draggable:hover {
  .cal-resize-handle {
    &:after {
      content: ' ';
      width: 6px;
      height: 6px;
      background: $color-white;
      border: 1px solid currentColor;
      display: inline-block;
      position: absolute;
      left: calc(50% - 3px);
      z-index: 101;
    }

    &.cal-resize-handle-before-start:after {
      top: -5px;
    }
  }
}

.time-preview {
    color: $color-main-text;
    position: absolute;
    top: -14px;
    z-index: 100;
    left: 0;
    font-size: 12px;

  &._after {
    top: unset;
    bottom: -14px;
  }
}
