@import "mixins";
@import "variables";
@import "colors";

.more-menu {
  min-width: 200px;
}

.more-menu__title {
  @include font-stack-main;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: left;
  text-transform: uppercase;
  margin: $grid * 2 $grid * 3;
  color: $color-grey-60;
}


.more-menu__link {
  display: block;
  font-size: 14px;
  padding: $grid * 4;
  color: $color-main-text;
  @include font-stack-main;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}


.more-menu__link {
  font-size: 14px;
  color: $color-main-text;
  @include font-stack-main;

  ._selected {
    &.done {
      display: block;
    }
  }

  &._column {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    margin: 0;
    padding: $grid * 2 $grid * 3;

    &:hover {
      background-color: $color-bg-gray;
    }

    &.color {
      @include statusColors;
    }
  }


  &._disabled {
    opacity: 0.5;
    cursor: default;

    &:hover {
      cursor: default;
      opacity: 0.5;
    }
  }

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .icon {
    @include icon-color($color-dark-grey-text);
    width: 20px;
    height: 20px;
    font-size: 20px;

  }
}


.more-menu__link {
  display: block;
  font-size: 14px;
  padding: 6px $grid * 4;
  white-space: nowrap;
  color: $color-main-text;
  @include font-stack-main;

  &:hover {
    cursor: pointer;
    opacity: 0.8;

  }
}


.more-menu {
  &._task {
    border-radius: 8px;
    .mat-mdc-menu-content {
      padding: 0;
    }
  }

  &._importance {
    box-shadow: $box-shadow3;

    &._short {
      .more-menu__link {
        min-width: 155px;
      }
    }

    .more-menu__title {
      margin-left: 24px;
      margin-top: 16px;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      text-transform: uppercase;
      color: $color-grey-68;
    }

    .more-menu__link {
      display: flex;
      min-width: 240px;
      align-items: center;
      height: 36px;
      padding: 0 16px 0 16px;
      box-sizing: border-box;
      color: $color-main-text;
      font-size: 14px;

      &:hover {
        background-color: $color-light-grey-24;
      }
    }

    .icon {
      margin-right: 8px;
    }

    .mat-divider {
      margin: 8px 0;
    }
  }
}
