@import "variables";

.expansion-panel-function-users__header {
  padding: 12px !important;
}

.section._pos,
.section._sub {
  .mat-expansion-panel-content {
    padding: 0 12px;
    border-top: 1px solid $color-light-grey;

    .user-box:last-child {
      margin-bottom: 12px;
    }
  }

  .mat-expanded {
    .expansion-panel-function-users__header {
      background-color: $color-light-grey-10;

      &:hover {
        background-color: $color-light-grey-10 !important;
      }
    }
  }
}

.mat-expansion-indicator {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-panel {
  .mat-expansion-panel-body {
    padding-bottom: 0;
  }
}

mat-accordion.accordion__container {
  display: flex;
  box-shadow: none;
  border-radius: 0;
  flex-direction: column;
  gap: 24px;

  .mat-expansion-panel-header {
    padding: 0;

    &:hover {
      background-color: $color-light-grey-68 !important;
      border-radius: 0 8px 8px 0;
    }
  }

  .mat-expansion-panel {
    box-shadow: none;
    margin-bottom: 0;
    background: $color-main-bg;

    .mat-expansion-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin: 0;
      background: $color-light-grey-68;

      &::after {
        margin-bottom: 3px;
      }
    }
  }

  .accordion__header {
    padding: 0 16px;
  }

  .accordion__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 12px;
  }

  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  .accordion__panel {
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid $color-grey;
  }

  .txt {
    font-size: 16px;
    font-weight: 500;
    color: $color-main-text;
  }
}
