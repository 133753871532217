/* Переменные-цвета проекта
 */

// Основные цвета
$color-black: #000;
$color-main-text: #181C3C;
$color-dark-grey-text: #4A586D;
$color-input-title: rgba(0, 0, 0, 0.5);
$color-light-txt: rgba(0, 0, 0, 0.3);
$color-border: rgba(0, 0, 0, 0.2);
$color-input-disable: rgba(0, 0, 0, 0.12);
$color-button-border: rgba(0, 0, 0, 0.06);
$color-main-bg: #F1F5F8;
$color-white: #fff;


// Основые оттенки синего
$color-blue-hover: #2072EB;
$color-blue-pressed: #2360D8;
$color-blue-active: #0593FF;
$color-blue-default: #1985FF;
$color-blue-light: rgba(25, 133, 255, 0.1);
$color-blue-light-70: rgba(25, 133, 255, 0.7);
$color-blue-marker: #72CCFd;
$color-blue: #1985ff;
$color-blue-hover-light: rgba(25, 133, 255, 0.1);
$color-blue-hover-light-no-alpha: #e9f2ff;
$color-light-blue-txt: #42a5f5;
$color-dark-blue: #0049b0;

// чат? где? зачем? TODO: подставить что-то из цветов выше
$chat-back: #ccdff4;
$chat-mine: #e8f3ff;

// Основные оттенки серого
$color-grey: #7E858E;
$color-grey-100: rgba(126, 133, 142, 1);
$color-grey-68: rgba(126, 133, 142, 0.68);
$color-grey-60: rgba(126, 133, 142, 0.68);
$color-grey-40: rgba(126, 133, 142, 0.4);
$color-grey-40-no-alpha: #CBCED2;
$color-grey-4: rgba(126, 133, 142, 0.04);
$color-grey-bg: #FFFFFF;

// Основные оттенки светло-серого
$color-light-grey: #D7DFE9;
$color-light-grey-hover: rgba(215, 223, 233, 0.24);
$color-light-grey-bg: linear-gradient(0deg, rgba(107, 117, 129, 0.1), rgba(107, 117, 129, 0.1)), #FFFFFF;
$color-light-grey-bg-light: #E8F3FF;
$color-light-grey-new: #FBFCFD;
$color-light-grey-10: rgba(215, 223, 233, 0.1);
$color-light-grey-16: rgba(215, 223, 233, 0.16);
$color-light-grey-24-no-alpha: rgb(246 248 250);
$color-light-grey-24: rgba(215, 223, 233, 0.24);
$color-light-grey-40: rgba(215, 223, 233, 0.4);
$color-light-grey-68: rgba(215, 223, 233, 0.68);
$color-soft-gray: #f2f2f2;

// Кнопки
$color-button-hover: rgba(0, 0, 0, 0.04);
$color-button-toggle-off: #fafafa;
$color-button-focus: rgba(0, 0, 0, 0.12);
$color-button-active: rgba(25, 133, 255, 0.12);
$color-button-image-hover: rgba(255, 255, 255, 0.12);
$color-button-image-focus: rgba(255, 255, 255, 0.24);
$color-button-disabled: #d7dfe9;
$color-button-blue: #d1e6fd;

// Цвета для карточек
$color-card-bg-hover: rgba(26, 133, 255, 0.04);

// Success aka Green
$color-success: #2FBF6F;
$color-green-hover: #1DB05F;
$color-success-bg: #F3FFF8;
$color-success-bg-alpha: #F3FFF880;
$color-success-bg2: #ebfcf3;
$color-success-bg3: #DBFBEA;
$color-green-bg: #F3FFF8;
$color-green-bg3: rgba(219, 251, 234, 1);
$color-green-bg-tab: rgba(56, 221, 132, 0.1);
$color-green-bg-tag-dark: #CAF9E0;
$color-light-green: rgba(0, 200, 83, 0.7);

// Warning aka Orange
$color-warning: #FFB800;
$color-warning-2: #f99500;
$color-warning-pressed: #EAA800;
$color-warning-hover: #F2AE00;
$color-warning-bg: #FFF8E6;
$color-warning-bg2:  #FFF8E680;
$color-warning-bg3: #FFF0D9;
$color-orange-bg-tab: rgba(249, 149, 0, 0.1);
$color-yellow-marker: #FFFA0E;
$color-light-orange: rgba(255, 167, 38, 0.7);


// Error aka Red
$color-error: #F03E3E;
$color-error-pressed: #D7042A;
$color-error-hover: #EF0D36;
$color-error-bg: #FFF3F5;
$color-error-bg3: #FFE3E8;
$color-error-soft: #f68b8b;

$color-1c: #FFD08C;

//bg menu
$color-bg-menu: #2072eb;
$color-bg-menu-active: rgba(255, 255, 255, 0.1);

// input
$color-input-focus: #1a85ff;
$color-background-input-disabled: rgb(235, 235, 228);

// что-то старое
$color-bg-fade: rgba(0, 0, 0, 0.3);

$color-label: rgba(0, 0, 0, 0.4);
$color-org-select: #f2f2f2;
$color-grey-info: rgba(28, 28, 28, 0.87);
$color-grey-about-me: #7a7a7a;
$color-eclipse: #373737;
$color-nobel: #9b9b9b;

$color-task-opened: #f2f2f2;
$color-back-soft-blue: #f1f8ff;
$color-back-backlog: #d2ddea;

// tables

$color-table-68: rgba(165, 181, 202, 0.68);
$color-table-red-1: rgba(240, 62, 94, 0.07);
$color-table-red-2: rgba(240, 62, 94, 0.02);
$color-table-h-1: rgba(25, 133, 255, 0.24);
$color-table-h-2: rgba(25, 133, 255, 0.1);
$color-table-h-3: rgba(24, 28, 60, 0.4);

$color-port-gore: rgba(24, 28, 60, 0.4);
